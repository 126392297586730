import { computed, inject, ref } from 'vue';
import { pageKey, setMeta } from '@drapejs/core';
import { imageResize } from '@distancify/drapejs-storyblok';


export default function() {
  const page = inject(pageKey, ref());

  setMeta(() => {
    return {
      title: `${page.value.meta?.title || page.value.name} | Distancify`,
      lang: 'en',
      props: {
        description: page.value.meta?.description,
        'og:image': page.value.metaImage ? imageResize(page.value.metaImage, { width: 1200, height: 630 })?.filename : null,
      },
    };
  });
}